import React from 'react';
import { Helmet } from 'react-helmet';
import { withPrefix } from "gatsby"

const SEO = ({ title='', description='', location }) => {
  return (
    <Helmet titleTemplate="%s | veerXR">
      <html lang='en' />
      <title>{title}</title>
      <link rel="shortcut icon" href="favicon.ico" type="image/x-icon" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta charSet="utf-8" />
      <meta name="description" content={description} />

      <meta property="og:title" content={title} />
      <meta property="og:type" content="video.movie" />
      {location && <meta property="og:url" content={location.href} />}
      <meta property="og:image" content="veerXR-logo.svg" />
      <meta property="og:description" content={description} />
      <meta property="og:site_name" content="veerXR" />
      <script type="text/javascript" src={withPrefix('script.js')}></script>
      <script type="text/javascript" src="//cdn.iubenda.com/cs/iubenda_cs.js" charset="UTF-8" async></script>
    </Helmet>
  )
}

export default SEO;
